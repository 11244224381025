import "channels"
import "controllers"
import "trix"
import "@rails/actiontext"

import * as ActiveStorage from "@rails/activestorage"
import { I18n } from "i18n-js"
import { Turbo } from "@hotwired/turbo-rails"

ActiveStorage.start()
window.I18n = new I18n()
window.Turbo = Turbo
Turbo.session.drive = false
